import request from '@/utils/request';

export default {

  /*
  * 获取贵族订单列表
  * */
  getNobleList: params => request.get('/noble/orders', { params }),

  /**
   * @description 获取订单列表
   * @param {*} params
   * @returns
   */
  getNumberList: params => request.get('/goodid/orders', { params })
};
