import { Enum } from '../framework/utils';

export const dressType = new Enum(
  { alias: 'dressType1', text: '挂件道具', value: 1 },
  { alias: 'dressType2', text: '座驾道具', value: 2 },
  { alias: 'dressType3', text: '房间背景', value: 3 }
);

export const moneyType = new Enum(
  { alias: 'moneyType1', text: '金币', value: 1 },
  { alias: 'moneyType2', text: '砖石', value: 2 }
);

export const statusType = new Enum(
  { alias: 'statusType1', text: '已上架', value: 1 },
  { alias: 'statusType2', text: '已下架', value: 2 }
);

export const abc = {};
