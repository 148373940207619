import request from '@/utils/request';

export default {

  /**
   * @description 家族列表
   * @param {*} params
   * @returns
   */
  getFamilyList: params => request.get('/family/getfamily', { params }),

  /**
   * @description 家族成员
   * @param {*} params
   * @returns
   */
  getFUserList: params => request.get('/family/getfuser', { ...params }),
  

    /**
   * @description 家族成员
   * @param {*} params
   * @returns
   */
   operateOnFamily: params => request.post('/family/verify', { ...params }),

       /**
   * @description 家族成员
   * @param {*} params
   * @returns
   */
    operateOnFUser: params => request.get('/family/edituser', { ...params })

};
