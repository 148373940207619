import request from '@/utils/request';

export default {

  /*
  * 获取关卡列表
  * */
  getList: params => request.get('/game/stage', { params }),

  /*
  * 关卡详情
  * */
  detail: id => request.get(`/game/stage/${id}`),

  /*
  * 删除关卡
  * */
  delete: id => request.delete(`/game/stage/${id}`),

  /*
  * 更新关卡信息
  * */
  update: data => request.post('/game/stage', data),

  /*
  * 复制关卡
  * */
  copy: data => request.post('/game/stage/duplicate', data),

  /*
  * 预估关卡成本
  * */
  rate: data => request.post('/game/stage/rate', data)


};
