import request from '@/utils/request';

export default {

  /*
  * 获取活动列表
  * */
  getList: params => request.get('/activity', { params }),

  /*
  * 活动配置详情
  * */
  detail: id => request.get(`/activity/${id}`),

  /*
  * 更新活动基本信息
  * */
  changeBasic: data => request.post('/activity/basic', data),

  /*
  * 更新活动模板
  * */
  changeTemplate: data => request.post('/activity/template', data),

  /*
  * 发布活动
  * */
  publish: data => request.post('/activity/publish', data),

  /*
  * 下线活动
  * */
  offline: data => request.post('/activity/offline', data),

  /*
  * 切换房间是否显示
  * */
  changeRoomShow: data => request.post('/activity/room_switch', data),

  /*
  * 删除活动
  * */
  delete: id => request.delete(`/activity/${id}`),

  /*
  * 模拟1万次概率计算
  * */
  computedRate: data => request.post('/activity/rate', data),

  /*
  * 复制活动
  * */
  copy: data => request.post('/activity/duplicate', data)

};
