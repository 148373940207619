import Layout from '@/layout';

export default [{
    path: '/ucenter',
    component: Layout,
    redirect: '/ucenter/audit',
    name: 'ucenter',
    meta: { title: '用户中心', icon: 'user', noCache: false, permissions: ['starcret/audit'], position: 4 },
    children: [
      {
        path: 'order',
        component: () => import('@/views/ucenter/user'),
        name: 'uCenter',
        meta: { title: '用户中心', noCache: false, permissions: ['starcret/audit'], isParent: true }
      }, 
      {
        path: 'log',
        component: () => import('@/views/ucenter/banlog'),
        name: 'banUser',
        meta: { title: '封禁记录', noCache: false, permissions: ['starcret/audit'], isParent: true }
      }
    ]
  }];


