import request from '@/utils/request';


export default {
/*
*  靓号展示接口
*/

  getGoodNumberlist(data) {
    return request.get('/goodid/goodid_list', { params: data });
  },

  /*
*  goodid详情
*/

  detail(id) {
    return request.get(`/goodid/goodid_list/${id}`);
  },

  /*
*  更改靓号
*/
  update(data) {
    return request.post('/goodid/changes', data);
  },
  /*
   * 撤销
   */
  repeal(data) {
    return request.post('/goodid/restore', data);
  }
};
