<template>
  <div v-if="!item.hidden">
    <template v-if="hasOneShowingChild(item.children,item) && (!onlyOneChild.children||onlyOneChild.noShowingChildren)&&!item.alwaysShow">
      <app-link v-if="onlyOneChild.meta && !onlyOneChild.meta.outLinks" :to="resolvePath(onlyOneChild.path)">
        <el-menu-item :index="resolvePath(onlyOneChild.path)" :class="{'submenu-title-noDropdown':!isNest}">
          <item :icon="onlyOneChild.meta.icon||(item.meta&&item.meta.icon)" :el-icon="onlyOneChild.meta.elIcon||(item.meta&&item.meta.elIcon)" :title="showTitle(onlyOneChild, item)" />
        </el-menu-item>
      </app-link>
      <a v-else-if="onlyOneChild.meta && onlyOneChild.meta.outLinks" :href="outLinkHref(onlyOneChild.meta)" target="_blank">
        <el-menu-item :index="resolvePath(onlyOneChild.path)" :class="{'submenu-title-noDropdown':!isNest}">
          <item :icon="onlyOneChild.meta.icon||(item.meta&&item.meta.icon)" :el-icon="onlyOneChild.meta.elIcon||(item.meta&&item.meta.elIcon)" :title="showTitle(onlyOneChild, item)" />
        </el-menu-item>
      </a>
    </template>

    <el-submenu v-else ref="subMenu" :index="resolvePath(item.path)" popper-append-to-body>
      <template slot="title">
        <item v-if="item.meta" :icon="item.meta && item.meta.icon" :el-icon="item.meta && item.meta.elIcon" :title="item.meta.title" />
      </template>
      <sidebar-item
        v-for="child in item.children"
        :key="child.path"
        :is-nest="true"
        :item="child"
        :base-path="resolvePath(child.path)"
        class="nest-menu"
      />
    </el-submenu>
  </div>
</template>

<script>
// eslint-disable-next-line import/no-extraneous-dependencies
import path from 'path';
import Item from './Item';
import AppLink from './Link';
import FixiOSBug from './FixiOSBug';


export default {
  name: 'SidebarItem',
  components: { Item, AppLink },
  mixins: [FixiOSBug],
  props: {
    // route object
    item: {
      type: Object,
      required: true
    },
    isNest: {
      type: Boolean,
      default: false
    },
    basePath: {
      type: String,
      default: ''
    }
  },
  data() {
    // To fix https://github.com/PanJiaChen/vue-admin-template/issues/237
    // TODO: refactor with render function
    this.onlyOneChild = null;
    return {};
  },
  methods: {
    hasOneShowingChild(children = [], parent) {
      const showingChildren = children.filter(item => {
        if (item.hidden) {
          return false;
        }
        // Temp set(will be used if only has one showing child)
        this.onlyOneChild = item;
        return true;
      });

      // When there is only one child router, the child router is displayed by default
      if (showingChildren.length === 1) {
        return true;
      }

      // Show parent if there are no child router to display
      if (showingChildren.length === 0) {
        this.onlyOneChild = { ...parent, path: '', noShowingChildren: true };
        return true;
      }

      return false;
    },

    resolvePath(routePath) {
      return path.resolve(this.basePath, routePath);
    },

    outLinkHref(item) {
      const envstr = process.env.NODE_ENV;
      const { outLinks } = item;
      const token = localStorage.getItem('auth_token');
      return `${outLinks[envstr]}?token=${token}`;
    },

    showTitle(item, parent) {
      return item.meta.isParent ? parent.meta.title : item.meta.title;
    }

  }
};
</script>
