import Layout from '@/layout';

export default [{
    path: '/family',
    component: Layout,
    redirect: '/family/family',
    name: 'family',
    meta: { title: '家族中心', icon: 'user', noCache: false, permissions: ['starcret/audit'], position: 13 },
    children: [
      {
        path: 'order',
        component: () => import('@/views/family/family'),
        name: 'family',
        meta: { title: '家族中心', noCache: false, permissions: ['starcret/audit'], isParent: true }
      }
    ]
  }];


