import Layout from '@/layout';

export default [{
    path: '/pay',
    component: Layout,
    redirect: '/pay/paylist',
    name: 'pay',
    meta: { title: '充值列表', icon: 'pay', noCache: false, permissions: ['pay_manage'], position: 9 },
    children: [
        {
            path: 'paylist',
            component: () => import('@/views/pay/paylist'),
            name: 'payList',
            meta: { title: '充值列表', noCache: false, permissions: ['pay_manage'], isParent: true }
        }
    ]
}];
