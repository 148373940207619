import Layout from '@/layout';
import { generateAUDRoute } from '../../framework/utils/route';

export default [{
  path: '/task',
  component: Layout,
  redirect: '/task/condition',
  name: 'task',
  meta: { title: '任务管理', icon: 'form', permissions: ['task_manage'] },
  children: [
    {
      path: 'condition',
      component: () => import('@/views/task/condition.vue'),
      name: 'condition',
      meta: { title: '任务条件', permissions: ['task/condition'] }
    },
    {
      path: 'list',
      component: () => import('@/views/task/list.vue'),
      name: 'tasklist',
      meta: { title: '任务列表', permissions: ['task_manage'] }
    },
    ...generateAUDRoute('task', '任务', 'task')
  ]
}];
