<template>
  <section class="app-main" v-loading="!Enums">
    <template v-if="Enums">
      <template v-if="hasPermission">
        <keep-alive :include="cachedViews">
          <router-view :key="key" />
        </keep-alive>
      </template>
      <template v-else>
        <page404 />
      </template>
    </template>
  </section>
</template>
<script>
import page404 from './404';

export default {
  name: 'AppMain',
  components: {
    page404
  },
  // TODO:
  watch: {
    Enums: {
      handler: function(n, o) {
        console.log('-- appMain --', n, o)
      },
    }
  },
  computed: {
    cachedViews() {
      return this.$store.state.tagsView.cachedViews;
    },
    
    key() {
      return this.$route.path;
    },

    hasPermission() {
      return true;
    }
  }
};
</script>

<style lang="scss" scoped>
.app-main {
  /* 50= navbar  50  */
  min-height: calc(100vh);
  height: 100%;
  width: 100%;
  position: relative;
  overflow: hidden;
}

.fixed-header + .app-main {
  padding-top: 50px;
}

.hasTagsView {
  .fixed-header + .app-main {
    padding-top: 84px;
  }
}
</style>

<style lang="scss">
// fix css style bug in open el-dialog
.el-popup-parent--hidden {
  .fixed-header {
    padding-right: 15px;
  }
}
</style>
