import request from '@/utils/request';

export default {
  /*
   获取钻石查询数据
  */
  getDiamondinfo(data) {
    return request.get('/report/diamond/record', { params: data });
  },


  /**
   * @description 获取条件列表
   * @param {*} data
   * @returns
   */
  conditionList(data) {
    return request.get('/task/condition', { params: data });
  }
  
};
