import request from '@/utils/request';

export default {
  getList: data => request.get('/heyhey/sending', { params: data }),

  update: data => request.post('/heyhey/sending', data),

  detail: id => request.get(`/heyhey/sending/${id}`),

  delete: id => request.delete(`/heyhey/sending/${id}`),

  approval: (id, code) => request.put(`/heyhey/sending/approval/${id}?code=${code}`), // 审核通过

  reject: id => request.put(`/heyhey/sending/reject/${id}`), // 审核驳回

  code: params => {
    const idsStr = params.join(',');
    return request.get(`/heyhey/sending/audit/code/${idsStr}`, { params }); // 审核短信验证码
  },

  /**
   * @description 获取小助手消息模板
   * @returns
   */
  getMessageExplain: () => request.get('/heyhey/sending/assistant/template')


};
