import Layout from '@/layout';

export default [{
  path: '/order',
  component: Layout,
  redirect: '/order/nobility',
  name: 'member',
  meta: { title: '订单管理', icon: 'shopping', noCache: false, permissions: ['order_manage'], position: 7 },
  children: [
    {
      path: 'nobility',
      component: () => import('@/views/order/nobility'),
      name: 'nobleOrder',
      meta: { title: '贵族订单', icon: 'vip-member-fill', noCache: false, permissions: ['order/nobility'], isParent: true }
    }, {
      path: 'number',
      component: () => import('@/views/order/number'),
      name: 'numberOrder',
      meta: { title: '靓号订单', icon: 'component', noCache: false, permissions: ['order/number'], isParent: true }
    }
  ]
}];
