import request from '@/utils/request';

export default {
  /**
   * @description
   * @returns
   */
  loadFamilyNoticeDetail() {
    return request.get('/notice/family_president');
  },

  /**
   * @description 更新家族通告
   * @returns
   */
  updateFamilyNoticeDetail(data) {
    return request.post('/notice/family_president', data);
  }
  
};
