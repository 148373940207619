/**
 * @description 根据时间戳/Date实例转换成格化字符串
 * @param date 代表时间的Unix时间戳，时间对象或字符串形式的Unix时间戳
 * @param format 格式化形式参考 http://momentjs.cn/docs/ 年份、月份、日期的令牌 eg. YYYY-MM-DD HH:mm:ss
 */
var formatDate = function (date, format) {
    var result = '';
    var tempDate = null;
    if (typeof date === 'object') {
        tempDate = date;
    }
    else {
        tempDate = new Date(date);
    }
    if (/(Y+)/.test(format)) {
        result = format.replace(RegExp.$1, tempDate.getFullYear());
    }
    var month = tempDate.getMonth() + 1;
    var day = tempDate.getDate();
    var hours = tempDate.getHours();
    var minutes = tempDate.getMinutes();
    var seconds = tempDate.getSeconds();
    var tokens = {
        'M+': "".concat(month < 10 ? "0".concat(month) : month),
        'D+': "".concat(day < 10 ? "0".concat(day) : day),
        'H+': "".concat(hours < 10 ? "0".concat(hours) : hours),
        'm+': "".concat(minutes < 10 ? "0".concat(minutes) : minutes),
        's+': "".concat(seconds < 10 ? "0".concat(seconds) : seconds)
    };
    Object.keys(tokens).forEach(function (key) {
        if (new RegExp("(".concat(key, ")")).test(format)) {
            result = result.replace(RegExp.$1, tokens[key]);
        }
    });
    return result;
};
/**
 *
 * @description object 转数组
 * @todo 可能不需要设置为 formdata, 只需要设置contentType即可
 * @param {object} obj - 原始对象
 * @returns {object} formdata
 */
var objectToFormData = function (obj) {
    var formData = new FormData();
    // 递归出口
    if (typeof obj !== 'object' || !obj) {
        return obj;
    }
    Object.keys(obj).forEach(function (key) {
        formData.append(key, objectToFormData(obj[key]));
    });
    return formData;
};
/**
 * @description require.context取到的路由转为正式路由配置
 * @param routeFiles require.context
 */
var configRoutesToRoutes = function (routeFiles) {
    var configRoutes = routeFiles.keys().map(function (key) { return routeFiles(key).default; });
    configRoutes.sort(function (compared, current) {
        var comparedPosition = compared[0].meta.position;
        var currentPosition = current[0].meta.position;
        if (comparedPosition && currentPosition) {
            return comparedPosition - currentPosition;
        }
        return false;
    });
    return configRoutes || [];
};
/**
 * @description 下化线命名转驼峰 eg.case_type to caseType
 * @param str eg.case_type
 */
var toTf = function (str) {
    var reg = /_(\w)/g;
    var result = str.replace(reg, function ($0, $1) { return $1.toUpperCase(); });
    return result;
};
export default {
    formatDate: formatDate,
    objectToFormData: objectToFormData,
    configRoutesToRoutes: configRoutesToRoutes,
    toTf: toTf
};
