import Layout from '@/layout';

/*
* giftTableTemplate 路由器生成
* */

const routerGiftGenerator = routerData => {
  const createComponent = giftType => {
    const Component = () => import('@/views/gift/components/giftTableTemplate');
    return {
      render(h) {
        return h(Component, {
          props: { giftType }
        });
      }
    };
  };

  return routerData.map(item => {
    const { title, type, permissions } = item;
    return {
      path: type,
      component: createComponent(type),
      name: type,
      meta: { title, noCache: false, permissions }
    };
  });
};

export default [{
  path: '/gift',
  component: Layout,
  redirect: '/gift/dressList',
  name: 'gift',
  meta: { title: '礼物管理', icon: 'gift', noCache: false, permissions: ['gift_manage'], position: 6 },
  children: [{
    path: 'dressList',
    component: () => import('@/views/gift/dress-list'),
    name: 'dressList',
    meta: { title: '装扮列表', noCache: false, permissions: ['gift/dressList'] }
  }, {
    path: 'giveOutList',
    component: () => import('@/views/gift/give-out-list'),
    name: 'giveOutList',
    meta: { title: '发放列表', noCache: false, permissions: ['gift/giveOutList'] }
  }, {
    path: 'giftList',
    component: () => import('@/views/gift/gift-list'),
    name: 'giftList',
    meta: { title: '礼物列表', noCache: false, permissions: ['gift/giftList'] }
  },
  ...routerGiftGenerator([
    { type: 'shop', title: '普通礼物', permissions: ['gift/shop'] },
    { type: 'active', title: '活动礼物', permissions: ['gift/active'] },
    { type: 'marry', title: '表白礼物', permissions: ['gift/marry'] },
    { type: 'room', title: '房间礼物', permissions: ['gift/room'] },
    { type: 'nobility', title: '贵族礼物', permissions: ['gift/nobility'] }
  ])]
}];
