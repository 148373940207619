import { generateAUDRoute } from '../../framework/utils/route';
import Layout from '@/layout';

export default [{ path: '/banner',
  component: Layout,
  redirect: '/banner/list',
  name: 'banner',
  meta: { title: 'Banner管理', icon: 'component', permissions: ['banner/list'], position: 2 },
  children: [
    {
      path: 'list',
      component: () => import(/* webpackChunkName: 'banner' */ '@/views/banner/list'),
      name: 'BannerList',
      meta: { title: 'Banner管理' }
    },
    ...generateAUDRoute('banner', 'Banner管理', 'banner')
  ] }
];
