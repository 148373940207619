import request from '@/utils/request';

export default {

  /*
  * 获取模板列表
  * */
  getList: data => request.get('/template', { params: data }),

  /*
  * 获取模板数据
  * */
  detail: id => request.get(`/template/${id}`)

};
