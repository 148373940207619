import axios from 'axios';
import { Message } from 'element-ui';
import { Settings } from '@/config';

const BASE_URL = Settings[APP_ENV].halaRequestUrl;
const request = axios.create({
  baseURL: BASE_URL,
  withCredentials: true,
  headers: {
    'Cache-Control': 'no-cache'
  },
  timeout: 10000
});

/*
 * 返回拦截
 * */
request.interceptors.response.use(response => {
  const res = response.data;
  if (res.code !== 0) {
    Message({
      showClose: true,
      message: res.msg || 'Error',
      type: 'error',
      duration: 5 * 1000
    });

    if (res.code === 5000000) {
      // ...需要重新登陆--成功location.reload()
    }
    return Promise.reject(new Error(res.msg || 'Error'));
  }
  return res;
}, error => {
  const { status, statusText } = error.response || {};
  if (status === -1 && statusText === 'Unauthorized') {
    // eslint-disable-next-line no-undef
    store.dispatch('user/resetToken').then(() => {
      window.location.reload();
    });
  }
  if (status === 400) {
    Message({
      showClose: true,
      message: '参数错误',
      type: 'error',
      duration: 5 * 1000
    });
  } else {
    Message({
      showClose: true,
      message: '服务器异常',
      type: 'error',
      duration: 5 * 1000
    });
  }
 
  return Promise.reject(error);
});

export default {
/*
 白名单列表接口
*/
  getWhiteList(data) {
    return request.get('/api/v1/diamondagent/agents', { params: data });
  },

  /*
  添加白名单接口
*/
  addWhiteUser(data) {
    return request.post('/api/v1/diamondagent/agents', data);
  },

  /*
    删除白名单接口
  */
  delWhiteUser(uid) {
    return request.delete(`/api/v1/diamondagent/agents/${uid}`);
  }
  
};
