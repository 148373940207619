/**
 * @module/eventbus
 * @description eventbus
 */
import Vue from 'vue';

const eventBus = new Vue();

// const getEventBus = () => {
//   if (!eventBus) {
//     eventBus = new Vue();
//   }
//   return eventBus;
// };


export default eventBus;
