import request from '@/utils/request';

export default {

  /*
  * 获取兑换列表
  * */
  getList: params => request.get('/exchange', { params }),

  /*
  * 兑换详情
  * */
  detail: id => request.get(`/exchange/${id}`),

  /*
  * 更新兑换信息
  * */
  update: data => request.post('/exchange', data),

  /*
  * 启用
  * */
  online: id => request.put(`/exchange/${id}/online`),

  /*
  * 停用
  * */
  offline: id => request.put(`/exchange/${id}/offline`),

  /**
   * @description 快捷设置
   * @param {*} data
   * @returns
   */
  shortCutUpdate: (id, data) => request.put(`/exchange/${id}`, data)
};
