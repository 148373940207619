<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
export default {
  name: 'App'
};
</script>

<style lang="less" scoped>
::v-deep .el-form-item__content {
  color: #909399;
}
</style>

<style lang="scss">
  .img_box {
    display: block;
    height: 60px;
    width: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      max-height: 100%;
      max-width: 100%;
      height: unset;
      width: unset;
    }
  }
  
  $primary: #409eff;
  .el-dialog__header{
    background-color: $primary;
    color: #fff;
    padding-top: 10px;
    .el-dialog__title{
      color: #fff;
      font-size: 16px;
    }
    .el-dialog__headerbtn .el-dialog__close{
      color: #fff;
    }
    .el-dialog__headerbtn{
      top: 14px;
    }
  }
</style>
