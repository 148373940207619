import { generateAUDRoute } from '../../framework/utils/route';
import Layout from '@/layout';

export default [{
  path: '/config',
  component: Layout,
  redirect: '/config/props',
  name: 'config',
  meta: { title: '配置管理', icon: 'component', permissions: ['setting_manage'], position: 1 },
  children: [
    {
      path: 'props',
      component: () => import(/* webpackChunkName: 'config' */ '@/views/config/props'),
      name: 'PropsIndex',
      meta: { title: '道具列表', permissions: ['config/props'] }
    },
    ...generateAUDRoute('props', '道具', 'config'),
    {
      path: 'exchange',
      component: () => import(/* webpackChunkName: 'config' */ '@/views/config/exchange'),
      name: 'ExchangeIndex',
      meta: { title: '兑换列表', permissions: ['config/exchange'] }
    },
    {
      path: 'cdkbatch',
      component: () => import(/* webpackChunkName: 'config' */ '@/views/config/cdk-batch-list'),
      name: 'cdkIndex',
      // meta: { title: 'CDKey管理', permissions: ['config/props'] }
      meta: { title: 'CDKey管理', permissions: ['config/cdkbatch'] }
    },
    ...generateAUDRoute('exchange', '兑换', 'config'),
    {
      path: 'prediction',
      component: () => import(/* webpackChunkName: 'config' */ '@/views/config/prediction'),
      name: 'predictionIndex',
      meta: { title: '预言管理', permissions: ['config/prediction'] }
    },
    ...generateAUDRoute('prediction', '预言管理', 'config'),
    {
      path: 'formList',
      component: () => import('@/views/form/list'),
      name: 'formList',
      meta: { title: '自定义表单', permissions: ['config/formList'] }
    },
    ...generateAUDRoute('custom', '自定义表单', 'form'),
    ...generateAUDRoute('cdk', 'Cdk批次', 'config'),
    {
      path: 'cdklist/:id',
      component: () => import(/* webpackChunkName: 'config' */ '@/views/config/cdk-list'),
      name: 'cdklist',
      hidden: true,
      meta: { getTitle: to => `批次号为 ${to.params.id} 的Cdk明细`, permissions: ['config/props'] }
    },
    {
      path: 'prediction/topic/:id',
      component: () => import(/* webpackChunkName: 'config' */ '@/views/config/predictionTopic'),
      name: 'predictionTopicList',
      hidden: true,
      meta: { getTitle: to => `批次号为${to.params.id} 的预言题目`, permissions: ['config/props'] }
    }

    // {
    //   path: 'star',
    //   component: () => import(/* webpackChunkName: 'config' */ '@/views/config/star'),
    //   name: 'StarIndex',
    //   meta: { title: '周星列表', permissions: ['config/star'] }
    // },
    // ...generateAUDRoute('star', '周星', 'config'),
    // {
    //   path: 'announcement',
    //   component: () => import(/* webpackChunkName: 'config' */ '@/views/config/announcement'),
    //   name: 'AnnouncementIndex',
    //   meta: { title: '公告列表', permissions: ['config/props'] }
    // },
    // ...generateAUDRoute('announcement', '公告', 'config/announcement'),
    // {
    //   path: 'notice',
    //   component: () => import(/* webpackChunkName: 'config' */ '@/views/config/notice'),
    //   name: 'NoticeIndex',
    //   meta: { title: '通知列表', permissions: ['config/notice'] }
    // },
    // ...generateAUDRoute('notice', '通知', 'config')
  ]
}];
