import { generateAUDRoute } from '../../framework/utils/route';
import Layout from '@/layout';

export default [{
  path: '/activity',
  component: Layout,
  redirect: '/activity/index',
  name: 'activity',
  meta: { title: '活动管理', icon: 'activity', permissions: ['activity_manage'], position: 3 },
  children: [{
    path: 'index',
    component: () => import(/* webpackChunkName: 'activity' */ '@/views/activity/index'),
    name: 'ActivityIndex',
    meta: { title: '活动列表', permissions: ['activity/index'] }
  },
  ...generateAUDRoute('activity', '活动', 'activity'),
  {
    path: 'template',
    component: () => import(/* webpackChunkName: 'activity' */ '@/views/activity/template'),
    name: 'TemplateIndex',
    meta: { title: '模板列表', permissions: ['activity/template'] }
  }, {
    path: 'stage',
    component: () => import(/* webpackChunkName: 'activity' */ '@/views/activity/stage'),
    name: 'StageIndex',
    meta: { title: '关卡列表', permissions: ['activity/stage'] }
  },
  ...generateAUDRoute('stage', '关卡', 'activity')
  ]
}];
