import request from '@/utils/request';

export default {

  /**
   * @description 更新batch
   * @param {*} data
   * @returns
   */
  update(data) {
    return request.post('/cdk/batch/info/update', data);
  },

  /**
   * @description 新增batch
   * @param {*} data
   * @returns
   */
  create(data) {
    return request.post('/cdk/batch/info/generate', data);
  },

  /**
   * @description
   * @param {*} data
   * @returns
   */
  getList(data) {
    return request.post('/cdk/batch/getlist', data);
  },

  /*
  * 兑换详情
  * */
  detail: id => request.post('/cdk/batch/info/get', {
    batchId: id
  }),

  /**
   * @description cdk 明细
   * @param {*} data
   * @returns
   */
  cdkDetailList(data) {
    return request.post('/cdk/batch/cdkeys/details', data);
  }

};
